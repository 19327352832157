import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import styled from 'styled-components';
import SEO from '../components/seo';
import { ChevronLeft } from 'react-feather';

const PostContainer = styled.div`
  position: relative;
`;

const PostTitle = styled.h1`
  font-size: 3.5rem;
`;

const PostBody = styled.div`
  // override prism style for code blocks
  pre[class*='language-'] {
    margin-bottom: 1.5em;
  }
`;

const BackButton = styled(Link)`
  position: fixed;
  top: 106px;
  left: 13px;
  display: flex;
  &:visited {
    color: white;
  }
  &:before {
    width: 26px;
    left: 14px;
  }
`;

export default ({ data }) => {
  const post = data.markdownRemark;
  // const keywords = post.frontmatter.keywords ? post.frontmatter.keywords.join(',') : null
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <PostContainer>
        <BackButton to="/blog">
          <ChevronLeft size={32} />
        </BackButton>
        <PostTitle>{post.frontmatter.title}</PostTitle>
        <PostBody dangerouslySetInnerHTML={{ __html: post.html }} />
      </PostContainer>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
